// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;

@import '~aos/src/sass/aos.scss';
@import './theme.scss';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

$custom-typography: mat.define-typography-config(
	$font-family: '"Open Sans", Roboto, "Helvetica Neue", sans-serif',
	$button: mat.define-typography-level(14px, 36px, 600),
);

@include mat.all-component-typographies($custom-typography);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($web-theme);

/* You can add global styles to this file, and also import other style files */
@import 'app/styles/backdrop.scss';
@import 'app/styles/button.scss';
@import 'app/styles/devlog.scss';
@import 'app/styles/editor-preview.scss';
@import 'app/styles/mat-card.scss';
@import 'app/styles/mat-expansion-panel.scss';
@import 'app/styles/mat-form-field.scss';
@import 'app/styles/mat-table.scss';
@import 'app/styles/mat-tooltip.scss';
@import 'app/styles/scroll.scss';
@import 'app/styles/select.scss';
@import 'app/styles/snack-bar.scss';
@import 'app/styles/mat-checkbox.scss';
@import 'app/styles/mat-menu.scss';
@import 'app/directives/gain/gain.scss';
@import 'app/views/invest/portfolio/portfolio-dialog/portfolio-dialog.scss';
@import 'app/styles/colors.scss';


html,
body {
	image-rendering: -webkit-optimize-contrast;
	min-height: 100%;
}

body {
	font-family: 'Open Sans', Roboto, 'Helvetica Neue', sans-serif;
	margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	transition-delay: 99999s;
}

button {
	text-transform: uppercase;
}

.mat-error{
	color: $color-error!important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: $savvy-primary-color-300;
}

mat-form-field{
	line-height: 26px!important;
}

.mat-form-field-type-select-form-field {
	&:not(.mat-form-field-disabled) .mat-form-field-flex {
		cursor: pointer;
	}
}

.mat-form-field-appearance-fill savvy-select-form-field mat-icon {
	transform: translateY(-50%);
}

.mat-form-field-appearance-outline savvy-select-form-field mat-icon {
	top: calc(50% - 4px);
	transform: translateY(-50%);
}

.mat-form-field-appearance-outline .mat-form-field-infix{
	padding: 0.5em 0 1.0em 0 !important;
	max-height: 24px !important;
	background-color: transparent !important;
}

.mat-form-field-type-phone {
	.mat-form-field-label {
		margin-left: 2.4em;
	}
}

.mat-form-field .mat-input-element{
	background-color: transparent !important;
}

.faq{
	.mat-expansion-indicator::after {
		border-style: solid;
		border-width: 0 2px 2px 0;
		content: "";
		display: inline-block;
		padding: 5px !important;
		transform: rotate(45deg);
		vertical-align: middle;
		color: $savvy-primary-color-300!important;
	}
}

input:-webkit-autofill {
	-webkit-box-shadow: 0 0 0px 1000px $white inset!important;
}

button{
	width: 105px;
	height: 51px;
	display: block;
	float: left;
	padding: 0px;
	text-align: center;
	border-radius: 5px;
	//border: 1px solid $savvy-primary-color-300;
	background-color: transparent;
	font-size: 14px;
	font-family: Open Sans;
	color: $savvy-primary-color-300;
	text-transform: capitalize;
	cursor: pointer;
}

footer .mat-form-field-appearance-legacy {
	&.mat-focused {
		.mat-form-field-label {
			color: $neutral-color-400 !important;
		}
	}

	.mat-form-field-flex {
		background: $dark-white;
		border-radius: 4px;
		height: 50px;
	}

	.mat-form-field-infix {
		padding: 0.4375em 10px;

		input {
			color: $neutral-color-400;
		}
	}

	.mat-form-field-label {
		color: $neutral-color-400 !important;
		font-size: 1em;
	}

	label {
		padding: 1px 10px;
	}
}

::selection {
	//background: mat.get-color-from-palette($web-accent, 0.8);
	//color: white;
}

.material-icons {
	direction: ltr;
	display: inline-block;
	/* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
	font-family: 'Material Icons';
	-webkit-font-feature-settings: 'liga';
	font-size: 24px;
	-webkit-font-smoothing: antialiased;
	font-style: normal;
	font-weight: normal;
	letter-spacing: normal;
	line-height: 1;
	text-transform: none;
	white-space: nowrap;
	word-wrap: normal;
}

.radius .mat-dialog-container {
	border-radius: 16px !important;
	padding: 40px !important;
}

@font-face {
	font-family: 'Material Icons';
	font-style: normal;
	font-weight: 400;
	src: local('Material Icons'), url('./assets/material-icons.woff2') format('woff2'),
		url('./assets/material-icons.ttf') format('truetype');
}

// REPSPONSIVE
@media (max-width: 1279px) {

}

mat-label{
	color: $grey;
	//font: 400 12px "Open Sans", Roboto, "Helvetica Neue", sans-serif;
	font: 400 14px "Open Sans", Roboto, "Helvetica Neue", sans-serif;
}

.agreements{
	a{
		text-decoration: none;
	}
}



// MOBILE - ate width 768px
@media only screen and (max-device-width: 768px) {

	.next, button{
		width: 100%!important;
	}

	.mat-form-field-appearance-outline .mat-form-field-infix{
		//height: 45px!important; 
	}
	
	.mat-calendar-previous-button::after, .mat-calendar-next-button::after{
		display: none !important;
	}

}
