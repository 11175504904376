.devlog {
	background-color: rgba(white, .6);
	border-radius: 4px;
	box-shadow: 0 10px 10px 0 rgba(black, .05);
	left: 50%;
	padding: 5px;
	position: fixed;
	top: 0;
	transform: translateX(-50%);
	width: 300px;
	z-index: 9999;

	span {
		display: block;
	}
}

.devlog-error {
	color: red;
}

.devlog-info {
	color: black;
}
