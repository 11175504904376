@use '@angular/material' as mat;

.mat-autocomplete-panel.mat-autocomplete-visible {
	border-radius: 5px;
	box-shadow: 0 0 6px rgba(black, .16);
}

.mat-option {
	color: rgba(black, .7);
	font-size: 14px;
	text-transform: uppercase;

	&:not(.mat-selected) {
		&:hover {
			background: mat.get-color-from-palette($web-primary) !important;
			color: white;
			transition-duration: 0.5s;
		}
	}
}

.mat-option-ripple {
	border-bottom: 1px solid #f9a340;
	left: 16px !important;
	width: calc(100% - 36px);
}
