.savvy-scroll {
	&::-webkit-scrollbar {
		height: 8px;
		width: 8px;
	}

	&::-webkit-scrollbar-button {
		display: none;
	}

	&::-webkit-scrollbar-corner,
	&::-webkit-scrollbar-track {
		background-color: #f8f8f8;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #eee;

		&:hover {
			background-color: #e1e1e1;
		}

		&:active {
			background-color: #dcdcdc;
		}
	}
}
