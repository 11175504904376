@use '@angular/material' as mat;

table {
	width: 100%;
}

tr {
	cursor: pointer;
	transition: background-color 0.1s ease;
}

tr:hover {
	background-color: rgba(mat.get-color-from-palette($web-accent), 0.2);
}

tr[mat-header-row].mat-header-row {
	height: 40px;
}

th[mat-header-cell].mat-header-cell {
	background-color: $grey97;
	border-bottom-width: 0;
	color: #454545;
	font-size: 14px;
}

td[mat-cell].mat-cell {
	height: 60px;

	&:first-child {
		position: relative;
	}
}

[savvyColumn].savvy-column:first-child,
.savvy-th:first-child {
	padding-left: 20px;
}

[savvyColumn].savvy-column:last-child,
.savvy-th:last-child {
	padding-right: 20px;
}
