@use '@angular/material' as mat;

@import 'theme.scss';

.mat-form-field {

	.mat-input-element {
        color: $grey;
    }

	.mat-error {
		text-transform: none;
		color: #E34850;
	}

	&.mat-form-field-appearance-outline {
		text-transform: uppercase;

		.mat-form-field-outline {
			background: white;
			border-radius: 5px;

			&:hover {
				background: #f7f7f7;
			}

			&-start,
			&-gap,
			&-end {
				border: 1px solid #003087;
			}

			&-start,
			&-gap {
				border-right-style: none;
			}

			&-gap,
			&-end {
				border-left-style: none;
			}
		}

		&.mat-form-field-can-float.mat-form-field-should-float {
			.mat-form-field-outline-gap {
				border-top-color: #003087;
			}

			.mat-form-field-label {
				transform: translateY(-0.79373em) scale(0.75);
			}
		}
	}

	&.mat-form-field-invalid {
		&.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-outline-gap {
			border-top-color: mat.get-color-from-palette($web-warn);
			border-color: $color-error!important;
		}

		.mat-form-field-outline {
			&-start,
			&-gap,
			&-end {
				border-color: mat.get-color-from-palette($web-warn);
				border-color: $color-error!important;
			}
		}
	}

	&.mat-form-field-disabled {
		input {
			color: black;
		}

		.mat-form-field-outline {
			&-start,
			&-gap,
			&-end {
				border-color: darkgray !important;
			}
		}
	}

	&.mat-focused,
	&.mat-form-field-invalid {
		.mat-form-field-label {
			color: gray;
		}
	}

	&.mat-form-field-appearance-legacy {
		&.white {
			color: white;

			&.mat-form-field-invalid input {
				color: #ffe96e;
			}

			.mat-error {
				color: white;
			}

			.mat-form-field-underline,
			&.mat-form-field-invalid .mat-form-field-ripple,
			&.mat-form-field-invalid .mat-form-field-ripple.mat-accent,
			&.mat-focused .mat-form-field-ripple {
				background-color: white;
			}

			.mat-form-field-label,
			&.mat-form-field.mat-focused .mat-form-field-label,
			&.mat-form-field-invalid .mat-form-field-label {
				color: white;
			}
		}

		&.red {
			.mat-form-field-underline,
			&.mat-form-field-invalid .mat-form-field-ripple,
			&.mat-form-field-invalid .mat-form-field-ripple.mat-accent,
			&.mat-focused .mat-form-field-ripple {
				background-color: red;
			}

			.mat-form-field-label,
			&.mat-form-field.mat-focused .mat-form-field-label,
			&.mat-form-field-invalid .mat-form-field-label {
				color: red;
			}

			&.mat-form-field-invalid input {
				color: red;
			}
		}
	}
}
