@use '@angular/material' as mat;

@import 'theme.scss';

.savvy-rounded-menu.mat-menu-panel {
	border-radius: 10px;

	mat-divider {
		border-color: mat.get-color-from-palette($web-accent, 400);
		margin: 0 10px;
	}
}
