@use '@angular/material' as mat;

@import 'theme.scss';

.accent-backdrop {
	background-color: rgba(mat.get-color-from-palette($web-accent), 0.1);
}

.panel-with-shadow {
	@include box-shadow(0.1);

	border-radius: 5px;
	position: relative !important;

	.mat-dialog-container {
		border-radius: 10px;
		box-shadow: none;
	}
}

.tooltip-with-shadow {
	@include box-shadow(0.2);

	border-radius: 5px;
	position: relative !important;

	.mat-dialog-container {
		border-radius: 10px;
		box-shadow: none;
	}
}

.panel-without-paddings .mat-dialog-container {
	padding: 0;
}
