$color-white: #FFFFFF;
$white: #FFFFFF;
$color-black: #000000;
$black: #000000;
$color-gray: #F4F4F4;
$color-error: #E34850;
$error: #E34850;
$color-sucess: #8FB964;
$color-toast-sucess: #2D9D78;
$color-disabled: #eeeeee;
$color-focus: #000F29;
$checked: #00308714;
$shadow: #0003;

$savvy-primary-color-100: #EFFCFA;
$savvy-primary-color-200: #57D9CB;
$savvy-primary-color-300: #003087;
$savvy-primary-color-300-hover: #EFFCFA;
$savvy-primary-color-400: #000F29;
$savvy-neutral-color-400: #454545;

$neutral-color-100: #EAEAEA;
$neutral-color-200: #999999;
$neutral-color-300: #707070;
$neutral-color-400: #454545;

$dark-white: #f5f5f5;
