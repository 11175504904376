figure.image img {
	width: 100%;
}

figure.media,
figure.media iframe {
	min-height: 300px;
}

.player figure.media {
	height: 100%;
	margin: 0;

	iframe {
		border-radius: 20px;
	}
}
