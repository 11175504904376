@use '@angular/material' as mat;

$fuchsia: (
	/*50: #f5e5ef,
	100: #e6bdd7,
	200: #d591bc,
	300: #c465a1,
	400: #b7448c,
	500: #aa2378,
	600: #a31f70,
	700: #991a65,
	800: #90155b,
	900: #7f0c48,
	A100: #ffb0d6,
	A200: #ff7dbb,
	A400: #ff4aa0,
	A700: #ff3093,*/
	50: #003087,
	100: #003087,
	200: #003087,
	300: #003087,
	400: #003087,
	500: #003087,
	600: #003087,
	700: #003087,
	800: #003087,
	900: #003087,
	A100: #003087,
	A200: #003087,
	A400: #003087,
	A700: #003087,
	contrast: (
		50: #000,
		100: #000,
		200: #000,
		300: #000,
		400: #fff,
		500: #fff,
		600: #fff,
		700: #fff,
		800: #fff,
		900: #fff,
		A100: #000,
		A200: #000,
		A400: #000,
		A700: #fff,
	),
);

$orange: (
	/*50 : #fef4e8,
	100 : #fde3c6,
	200 : #fcd1a0,
	300 : #fbbf79,
	400 : #fab15d,
	500 : #f9a340,
	600 : #f89b3a,
	700 : #f79132,
	800 : #f6882a,
	900 : #f5771c,
	A100 : #fff,
	A200 : #fff9f6,
	A400 : #ffdac3,
	A700 : #ffcaa9,*/
	50 : #003087,
	100 : #003087,
	200 : #003087,
	300 : #003087,
	400 : #003087,
	500 : #003087,
	600 : #003087,
	700 : #003087,
	800 : #003087,
	900 : #003087,
	A100 : #003087,
	A200 : #003087,
	A400 : #003087,
	A700 : #003087,
	contrast: (
		50 : #000,
		100 : #000,
		200 : #000,
		300 : #000,
		400 : #000,
		500 : #000,
		600 : #000,
		700 : #000,
		800 : #000,
		900 : #000,
		A100 : #000,
		A200 : #000,
		A400 : #000,
		A700 : #000,
	),
);

$redish: (
	/*50 : #fee2e8,
	100 : #fbb8c7,
	200 : #f988a1,
	300 : #f7587b,
	400 : #f5355f,
	500 : #f31143,
	600 : #f10f3d,
	700 : #ef0c34,
	800 : #ed0a2c,
	900 : #ea051e,
	A100 : #fff,
	A200 : #ffdfe1,
	A400 : #ffacb2,
	A700 : #ff929a,*/
	50 : #003087,
	100 : #003087,
	200 : #003087,
	300 : #003087,
	400 : #003087,
	500 : #003087,
	600 : #003087,
	700 : #003087,
	800 : #003087,
	900 : #003087,
	A100 : #003087,
	A200 : #003087,
	A400 : #003087,
	A700 : #003087,
	contrast: (
		50 : #000,
		100 : #000,
		200 : #000,
		300 : #000,
		400 : #fff,
		500 : #fff,
		600 : #fff,
		700 : #fff,
		800 : #fff,
		900 : #fff,
		A100 : #000,
		A200 : #000,
		A400 : #000,
		A700 : #000,
	)
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$web-primary: mat.define-palette($fuchsia, 500);
$web-accent: mat.define-palette($orange, 500);

// The warn palette is optional (defaults to red).
$web-warn: mat.define-palette($redish, 500);

// Create the theme object (a Sass map containing all of the palettes).
$web-theme: mat.define-light-theme($web-primary, $web-accent, $web-warn);

:root {
	--web-primary: #{mat.get-color-from-palette($web-primary)};
	--web-warn: #{mat.get-color-from-palette($web-warn)};
	--web-accent: #{mat.get-color-from-palette($web-accent)};
}

$ghost-fuchsia: #e4dad8;
$grey: #454545;
$dark-white: #f5f5f5;
$white: #ffffff;
$grey97: #f7f7f7;
$light-gray: #e2e2e2;
$header-height: 60px;


$profit-up: #8fb964;
$profit-down: #003087;

@mixin box-shadow($opacity, $invert: false) {
	box-shadow: 0 #{5 * if($invert, -1, 1)}px 20px rgba(black, $opacity);
}

@mixin gradient($deg, $percent: 70%) {
	background: linear-gradient($deg, mat.get-color-from-palette($web-primary), mat.get-color-from-palette($web-warn) 35%, mat.get-color-from-palette($web-accent) $percent);
}

@mixin fullscreen-container($internal-menu: false) {
	box-sizing: border-box;
	display: block;
	margin-top: if($internal-menu, 35px, 0);
	min-height: calc(100vh - #{$header-height + if($internal-menu, 35px, 0)});
	position: relative;
}

@mixin grid-layout(){
	display: grid;
	grid-column-gap: 20px;
	grid-template-columns: repeat(12, 1fr);
}

@mixin gradient-border($radius: 0) {
	border-radius: $radius;
	position: relative;

	&::before {
		@include gradient(180deg);

		border-radius: calc(#{$radius} + 4px);
		bottom: 0;
		content: '';
		left: 0;
		margin: -4px;
		position: absolute;
		right: 0;
		top: 0;
		z-index: -1;
	}
}

@mixin pulse-animation() {
	animation: pulse 3s infinite;
	border-radius: inherit;
	height: 100%;
	position: absolute;
	width: 100%;
	z-index: 5;
}

@mixin wave-background($offset, $height, $invert: false) {
	@include gradient(45deg);

	@if $invert {
		bottom: $offset;
		transform: rotate(180deg);
	}

	@else {
		top: $offset;
	}

	height: $height;
	overflow: hidden;
	position: absolute;
	width: 100%;
	z-index: -50;
}

@mixin link($color) {
	background-image: linear-gradient(currentColor, currentColor);
	background-position: 0 100%;
	background-repeat: no-repeat;
	background-size: 0 1px;
	color: $color;
	display: inline;
	text-decoration: none;
	transition: background-size .2s ease;

	&:hover,
	&:active,
	&:focus {
		background-size: 100% 1px;
	}
}

@mixin hide-scroll() {
	-ms-overflow-style: none;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}
}

@mixin progress() {
	width: 400px;

	&__bar {
		background-color: rgba(#f9a340, .1);
		border-radius: 10px;
		box-sizing: border-box;
		height: 15px;
		left: 0;
		top: calc(50% - 11px);
		width: 100%;

		.filled {
			background-color: mat.get-color-from-palette($web-accent);
			border-radius: inherit;
			height: inherit;
			transition: 0.5s ease;
		}
	}
}

@keyframes pulse {
	0% {
		background: #eaeaea;
	}

	50% {
		background: white;
	}

	100% {
		background: #eaeaea;
	}
}

body {
	--max-width: 1228px;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
    background-color: #003087;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
    background-color: #fff;
}

::ng-deep .custom-frame {
	& .mat-checkbox-background, .mat-checkbox-frame {
	  border-radius: 70% !important;
	}
}

tr:hover{
	background-color: #00308752 !important;
}
