@use '@angular/material' as mat;

@import 'theme.scss';
@import 'colors.scss';

.mat-button{
	width: 100%;
	text-transform: none;
	background-color: $savvy-primary-color-300 !important;
	color: $white !important;
}
/*
.mat-button:hover{
	background-color: $savvy-primary-color-400 !important;
}*/

.mat-button-disabled{
	background-color: $neutral-color-100 !important;
	color: $neutral-color-200 !important;
}

.mat-button-disabled:hover{
	background-color: $neutral-color-100 !important;
}

.mat-flat-button:not([disabled]).mat-primary:hover,
.mat-raised-button:not([disabled]).mat-primary:hover {
	background-color: mat.get-color-from-palette($web-accent);
}

.mat-flat-button[disabled].mat-primary,
.mat-raised-button[disabled].mat-primary {
	color: $white;
}

.mat-stroked-button,
.mat-flat-button,
.mat-raised-button {
	height: 40px;
	transition-duration: .1s;
}

.mat-stroked-button:not([disabled]).mat-primary {
	border-color: mat.get-color-from-palette($web-primary);

	&:hover {
		border-color: $savvy-primary-color-300;
		color: mat.get-color-from-palette($web-accent);
	}
}

.mat-stroked-button.mat-warn:not([disabled]) {
	border-color: $color-error;
}
