@import 'theme.scss';

.savvyGain {
	color: var(--savvy-gain-color);
	position: relative;

	&:not([savvyGainNoArrow='true'])::before {
		content: var(--savvy-gain-arrow);
		display: inline-block;
		font-family: 'Material Icons', monospace;
		font-size: 18px;
		vertical-align: middle;
	}

	&-positive {
		--savvy-gain-arrow: 'arrow_drop_up';
		--savvy-gain-color: #{$profit-up};
	}

	&-negative {
		--savvy-gain-arrow: 'arrow_drop_down';
		--savvy-gain-color: #{$profit-down};
	}

	&-uncertain {
		--savvy-gain-color: #a5a5a5;
	}

	&[savvyGainExtended='true'] {
		color: inherit;

		&::before {
			color: var(--savvy-gain-color);
			transform: rotateZ(45deg);
			vertical-align: bottom;
		}

		&.savvyGain-positive {
			--savvy-gain-arrow: 'arrow_upward';
			--savvy-gain-color: #54cc5e;
		}

		&.savvyGain-negative {
			--savvy-gain-arrow: 'arrow_forward';
		}
	}

	&[savvyGainParenthesis='true'] {
		padding-right: 4px;

		&::before {
			margin-right: -16px;
		}

		.savvyGain-open,
		.savvyGain-close {
			position: absolute;
		}

		.savvyGain-open {
			left: 0;
		}

		.savvyGain-close {
			right: 0;
		}
	}
}
