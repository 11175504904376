@use '@angular/material' as mat;

@import 'theme.scss';

.mat-card {
	border-radius: 10px !important;

	&:not([class*='mat-elevation-z']) {
		box-shadow: 0 0 20px rgba(black, 0.1);
	}
}

.mat-card.savvy-mat-card-no-bottom {
	padding-bottom: 0;
}

mat-card-title {
	color: mat.get-color-from-palette($web-primary);
	font-family: 'Exo 2', sans-serif;
	font-size: 24px;
	margin-bottom: 14px;
}
